angular
    .module('CareGuard')
    .controller('nonRedeemedClaimsController', nonRedeemedClaimsController);

nonRedeemedClaimsController.$inject = [
    '$state',
    '$toastr',
    '$q',
    'payeeService',
    'nonRedeemedClaimsService',
    'addressService',
    'claimService',
    'documentService',
    'claimStatusId',
    'LxDialogService'
];

function nonRedeemedClaimsController(
    $state,
    $toastr,
    $q,
    payeeService,
    nonRedeemedClaimsService,
    addressService,
    claimService,
    documentService,
    claimStatusId,
    LxDialogService) {

    var vm = this;

    vm.availablePageSizes = [5, 10, 20];
    vm.dataFilters = {};
    vm.filteredData = {
        totalTransactionsCount: 0,
        totalPayableAmount: 0.00,
        payeeWithClaims: []
    };
    vm.aggregatedData = [];

    vm.claimStatuses = [];
    vm.claimStatusMap;

    vm.searchTaxId = `${payeeService.searchPayeesRoute()}?searchBy=`;

    vm.closeOthers = false;
    vm.isPayeeFilterEntered = false;
    vm.showNoResults;

    vm.originalPayeeCopy = {};
    vm.pendingPayeeSelect = {};
    vm.availableAddressesSelect = [];

    vm.filterData = filterData;
    vm.setFilterPayee = setFilterPayee;
    vm.setNewPayee = setNewPayee;

    const payeeAddressSelectionPopupId = 'payeeAddressSelection';

    (() => {

        initializeCommonFilters();
        initializeSpecificFilters();

        getData().then((data) => {
            if (data) {
                return getClaimStatusData();
            }
        });

    })();

    function initializeCommonFilters() {

        vm.dataFilters = {
            pageNumber: 1,
            pageSize: 10,
            totalRows: 0
        };
    }

    function initializeSpecificFilters() {
        if ($state.params.ClaimId) {
            vm.dataFilters.claimId = $state.params.ClaimId;
        }
    }

    function getData() {
        return getNonRedeemedClaimsData().then((claims) => {
            if (!claims || !claims.length) {
                showNoResultsMessage();
                return;
            }
            calculateTotals();
            filterData();
            return claims;
        });
    }

    function filterData() {
        filterNonRedeemedClaimsData();
        applyPagination();
    }

    function getNonRedeemedClaimsData() {

        return nonRedeemedClaimsService.getAwaitsReissueClaims().then(({ data: nonRedeemedClaims }) => {

            if (!nonRedeemedClaims.length) {
                return;
            }

            const claimIds = nonRedeemedClaims.map(claim => claim.claimId);

            return claimService.getClaimHeadersByIds(claimIds).then(({ data: claims }) => {

                const filteredClaims = claims.filter(claim =>
                    claim.claimStatusID === claimStatusId.NonRedeemedPayment || claim.claimStatusID === claimStatusId.ReadyForReissue);

                if (!filteredClaims.length) {
                    return;
                }

                const uniquePayeeIds = [...new Set(filteredClaims.map(claim => claim.payeeID))];
                return payeeService.getPayeesByIdsByBulk({ ids: uniquePayeeIds }).then(({ data: payees }) => {

                    const payeeIds = payees.map(payee => payee.id);

                    return addressService.getAddressesByPayeeIds(payeeIds).then(({ data: addresses }) => {
                        if (!addresses.length) return;

                        const payeesWithAddresses = payees.map(payee => {
                            payee.addresses = addresses.find(addr => addr.payeeId === payee.id)?.payeeAddresses;
                            return payee;
                        });

                        return vm.aggregatedData = aggregateData({ nonRedeemedClaims, filteredClaims, payeesWithAddresses });
                    })
                })
            })
        })
    }

    function showNoResultsMessage() {
        $toastr.show(`Non-redeemed claims were not found.`, `warning`);
        vm.showNoResults = true;
    }

    function aggregateData(data) {
        const { nonRedeemedClaims, filteredClaims, payeesWithAddresses } = data;

        const combinedClaims = filteredClaims.map(claim => ({
            ...claim,
            ...nonRedeemedClaims.find(nrClaim => nrClaim.claimId === claim.id)
        }));

        const aggregatedData = [];

        payeesWithAddresses.forEach(payee => {
            const payeeData = {
                id: payee.id,
                name: payee.name,
                npiNumber: payee.npiNumber,
                taxID: payee.taxID,
                addresses: [],
                transactionCount: 0,
                totalPayable: 0

            };

            payee.addresses.forEach(address => {
                const relatedClaims = combinedClaims.filter(claim => claim.payeeAddressID === address.payeeAddressID);

                if (relatedClaims.length > 0) {
                    const addressData = {
                        id: address.id,
                        payeeAddressId: address.payeeAddressID,
                        address1: address.address1,
                        address2: address.address2,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                        claims: relatedClaims.map(claim => ({
                            id: claim.id,
                            transactionId: claim.transactionId,
                            payableAmount: claim.payableAmount,
                            paidDate: claim.paidDate,
                            clearingHouseVendorName: claim.clearingHouseVendorName,
                            statusId: claim.claimStatusID
                        }))
                    };

                    payeeData.addresses.push(addressData);

                    payeeData.transactionCount += new Set(relatedClaims.map(claim => claim.transactionId)).size;
                    payeeData.totalPayable += relatedClaims.reduce((sum, claim) => sum + claim.payableAmount, 0);
                }
            });

            if (payeeData.addresses.length > 0) {
                aggregatedData.push(payeeData);
            }
        });

        return aggregatedData;
    }

    function calculateTotals() {
        vm.filteredData.totalTransactionsCount = vm.aggregatedData.reduce((sum, payeeData) => sum + payeeData.transactionCount, 0);
        vm.filteredData.totalPayableAmount = vm.aggregatedData.reduce((sum, payeeData) => sum + payeeData.totalPayable, 0);
    }

    function filterNonRedeemedClaimsData() {
        const payeesWithAddresses = vm.aggregatedData;
        const filters = vm.dataFilters;
        const noFiltersEntered = !filters.claimId && !filters.transactionId && !filters.payeeId;
        vm.showNoResults = false;
        let filteredPayees;

        if (noFiltersEntered) {
            filteredPayees = payeesWithAddresses;
        } else {
            function matchesFilters(claim, payee) {

                const claimIdMatch = !filters.claimId || claim.id == filters.claimId;
                const transactionIdMatch = !filters.transactionId || claim.transactionId == filters.transactionId;
                const payeeIdMatch = !filters.payeeId || payee.id == filters.payeeId;

                return claimIdMatch && transactionIdMatch && payeeIdMatch;
            }

            filteredPayees = payeesWithAddresses.filter(payee => {
                const filteredAddresses = payee.addresses.filter(address => {
                    const filteredClaims = address.claims.filter(claim => matchesFilters(claim, payee));
                    return filteredClaims.length > 0;
                });

                return filteredAddresses.length > 0;
            });
        }
        if (payeesWithAddresses.length && !filteredPayees.length) {
            vm.showNoResults = true;
            $toastr.show(`Non-redeemed claims were not found by filtering criterias.`, `warning`);
        }

        vm.filteredData.indexedPayeeWithClaims = filteredPayees
            .sort((a, b) => b.totalPayable - a.totalPayable)
            .map((obj, index) => ({ ...obj, index }));

        vm.dataFilters.totalRows = vm.filteredData.indexedPayeeWithClaims.length;
    }

    function applyPagination() {
        const { pageNumber, pageSize } = vm.dataFilters;

        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = pageNumber * pageSize;

        vm.filteredData.payeeWithClaims = vm.filteredData.indexedPayeeWithClaims.slice(startIndex, endIndex);
    }

    vm.selectPayee = function (event = null) {

        if (!event) return;
        event.stopPropagation();
    }

    vm.anyResultsFound = () => vm.filteredData.payeeWithClaims.length > 0;

    vm.anyPayeeSelected = () => vm.filteredData.payeeWithClaims.some(payee => payee.checked === true);

    vm.isAnyFilterEntered = () => vm.dataFilters.claimId || vm.dataFilters.transactionId || vm.dataFilters.payeeId;

    vm.isAnyNonRedeemedClaim = (payee) => {
        return payee.addresses.some(address =>
            address.claims.some(claim => claim.statusId === claimStatusId.NonRedeemedPayment)
        )
    }

    function getClaimStatusData() {
        return claimService.getClaimStatuses().then(({ data: statuses }) => {
            vm.claimStatuses = statuses;
            vm.claimStatusMap = buildClaimStatusMap(statuses);
        });
    }

    function buildClaimStatusMap(statuses) {
        return statuses.reduce((map, obj) => map.set(obj.id, obj.status), new Map());
    }

    vm.setReadyForReissue = () => {
        const selectedPayeeIds = [];
        const claimIds = [];

        vm.filteredData.payeeWithClaims.forEach(payee => {
            if (payee.checked) {
                selectedPayeeIds.push(payee.id);
                claimIds.push(
                    ...payee.addresses.flatMap(address =>
                        address.claims
                            .filter(claim => claim.statusId === claimStatusId.NonRedeemedPayment)
                            .map(claim => claim.id)
                    )
                );
            }
        });

        return claimService.updateClaimStatuses({ Ids: claimIds, StatusID: claimStatusId.ReadyForReissue })
            .then(() => {
                return getData().then(() => {
                    setPayeesAccordionOpen(selectedPayeeIds);
                    $toastr.show('All selected claims have been moved to Ready For Reissue!', 'success');
                });
            });
    }

    function setPayeesAccordionOpen(payeeIds) {
        vm.filteredData.payeeWithClaims.forEach(payee => {
            payee.isAccordionOpen = payeeIds.includes(payee.id);
        });
    }

    function setFilterPayee({ originalObject: payee }) {
        if (!payee.taxID) {
            $toastr.show('There are no payees matching this tax ID. Please enter a valid one.', 'warning');
            return;
        }
        vm.dataFilters.payeeId = payee.id;
        vm.isPayeeFilterEntered = true;
        const { name, npiNumber, taxID } = payee;
        vm.dataFilters.payeeTitle = `${name} ${taxID} ${npiNumber}`;
    }

    vm.resetFilters = function resetFilters() {
        vm.dataFilters = {};
        vm.isPayeeFilterEntered = false;
        initializeCommonFilters();
        filterData();
    }

    function setNewPayee({ originalObject: newPayee }, currentPayee) {
        if (!newPayee.taxID) {
            $toastr.show('There are no payees matching this tax ID. Please enter a valid one.', 'warning');
            return;
        }
        return addressService.getAddressesByPayeeId(newPayee.id).then(({ data: addresses }) => {
            vm.isEditTaxIdOpen = false;
            vm.pendingPayeeSelected = newPayee;
            vm.availableAddressesSelected = addresses;

            const { originalCopy, ...payeeKeys } = currentPayee;
            vm.originalPayeeCopy = payeeKeys;

            LxDialogService.open(payeeAddressSelectionPopupId);
        });
    }

    vm.selectPayeeAddress = (address) => {

        replacePayee(address, vm.originalPayeeCopy.id, vm.pendingPayeeSelected);

        LxDialogService.close(payeeAddressSelectionPopupId);
    };

    vm.cancelPayeeAddressSelect = () => {
        vm.pendingPayeeSelect = {};
        vm.availableAddressesSelect = [];
        vm.originalPayeeCopy = {};
        LxDialogService.close(payeeAddressSelectionPopupId);
    };

    vm.closePayeeSearch = (payee, event) => {
        stopClick(event);
        cancelPayeeEdit(payee);
    };

    function replacePayee(newAddress, payeeIdToReplace, newPayee) {

        const payeeToReplace = vm.filteredData.payeeWithClaims.find(payee => payee.id === payeeIdToReplace);

        if (payeeToReplace) {

            const allClaims = payeeToReplace.addresses.reduce((claims, address) => claims.concat(address.claims), []);

            newAddress.claims = allClaims;
            newPayee.addresses = [newAddress];
            newPayee.index = payeeToReplace.index;
            newPayee.isAccordionOpen = payeeToReplace.isAccordionOpen;
            newPayee.transactionCount = payeeToReplace.transactionCount;
            newPayee.totalPayable = payeeToReplace.totalPayable;
            newPayee.isNewPayeeSelected = true;

            const { originalCopy, isAccordionOpen, isPayeeEditEnabled, ...payeeKeys } = payeeToReplace;
            newPayee.originalCopy = payeeKeys;

            const indexToReplace = vm.filteredData.payeeWithClaims.indexOf(payeeToReplace);
            vm.filteredData.payeeWithClaims.splice(indexToReplace, 1, newPayee);
        }
    }

    vm.enablePayeeEdit = function (payee, event) {
        stopClick(event);
        cancelAllOtherActiveEdits(vm.filteredData.payeeWithClaims, payee.id);
        payee.isPayeeEditEnabled = true;
        payee.isAccordionOpen = true;
    }

    vm.resetPayee = function (payee, event = null, keepOpen = true) {
        stopClick(event);

        Object.assign(payee, payee.originalCopy);
        cancelPayeeEdit(payee, keepOpen);
    }

    function cancelAllOtherActiveEdits(payees, selectedPayeeId) {
        payees.forEach(payee => {
            if (payee.id !== selectedPayeeId)
                vm.resetPayee(payee, null, false);
        })
    }

    function stopClick(event) {
        if (!event) return;

        event.stopPropagation();
        event.preventDefault();
    }

    function cancelPayeeEdit(payee, keepOpen) {
        payee.originalCopy = {};
        payee.newPayeeTaxID = null;
        payee.isPayeeEditEnabled = false;
        payee.isNewPayeeSelected = false;
        payee.isAccordionOpen = keepOpen;
    }

    vm.updatePayee = (payee) => {
        const claimIds = payee.addresses.flatMap(address => address.claims.map(claim => claim.id));
        let toastrMessage = 'New Payee and Payee Address have been assigned to the claims!';
        let toastrStatus = 'success';
        let payeeIdToShow = payee.id;

        return documentService.resetCurrentClaimEorByClaimIds(claimIds)
            .then(() => {
                return claimService.updateClaimPayeeWithAddress({ Ids: claimIds, PayeeId: payee.id, PayeeAddressId: payee.addresses[0].payeeAddressID });
            })
            .catch(() => {

                toastrMessage = 'Something went wrong while updating payee and addresses.';
                toastrStatus = 'error';
                payeeIdToShow = payee.originalCopy.id;
            })
            .finally(() => {

                return getData()
                    .then(() => {
                        setPayeesAccordionOpen([payeeIdToShow]);
                        $toastr.show(toastrMessage, toastrStatus);

                    })
            });
    };

    vm.changePage = function (pageNumber) {
        if (!pageNumber || pageNumber < 1) return;

        vm.dataFilters.pageNumber = pageNumber;
        cancelAllOtherActiveEdits(vm.filteredData.indexedPayeeWithClaims);
        applyPagination();
    }

    vm.changePageNumber = function () {
        vm.dataFilters.pageNumber = 1;
    }
}
